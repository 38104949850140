import React from "react";
import { Route, Routes } from "react-router-dom";

import LandingPage from "./components/LandingPage";

const AppRoutes = (): JSX.Element => {
  return (
    <>
      <Routes>
        {/* Fallback route */}
        {/* <Route path="*" element={<PageNotFound />} />
        <Route path="/500" element={<InternalServer />} /> */}
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </>
  );
};
export default AppRoutes;
