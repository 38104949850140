import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import HeroSection from "./HeroSection";
// import Pricing from "./Pricing";
import { APP_URL } from "../utils/constants";
import FAQSection, { FAQType } from "./FAQSection";
import CTA from "./CTA";

export interface HighlightType {
  icon: string;
  title: string;
  description: string;
}

const highlights: HighlightType[] = [
  {
    icon: "🔗",
    title: "Effortless Ticketing on Blockchain",
    description:
      "ChainCred makes ticketing seamless and secure with its state-of-the-art blockchain technology, maximizing your time and efficiency.",
  },
  {
    icon: "🎟️",
    title: "Non-Transferable NFT Tickets",
    description:
      "Craft your unique event experience with our non-transferable NFT tickets. With ChainCred, each ticket echoes your personal touch, style, and exclusivity.",
  },
  {
    icon: "💰",
    title: "Cost-Effective Blockchain Solution",
    description:
      "Our blockchain-based platform offers unmatched ticketing security and authenticity at an affordable price, perfect for businesses of all sizes.",
  },
  {
    icon: "🔐",
    title: "Unparalleled Security",
    description:
      "With ChainCred, your event ticketing is under the safeguard of top-tier blockchain technology. We adhere to strict data transmission protocols, ensuring your information is always secure.",
  },
];

const faqs: FAQType[] = [
  {
    q: "What is the purpose of ChainCred?",
    a: "ChainCred is designed to revolutionize your ticketing experience by transforming tickets into unique, non-transferable NFTs, providing enhanced security and an end to counterfeit issues.",
  },
  {
    q: "How does ChainCred work?",
    a: "ChainCred integrates seamlessly into your ticketing system. It ensures every ticket you issue becomes a unique, non-transferable NFT on the blockchain, providing an untainted chain of credibility and ensuring authenticity.",
  },
  {
    q: "Is ChainCred free to use?",
    a: "ChainCred operates on a pay-per-use model, given the inherent blockchain network costs. While our platform itself doesn't charge a subscription fee, the minting of each ticket as an NFT incurs a gas fee, which is a small payment that fuels blockchain operations. Furthermore, each transaction on our platform involves a minimal service fee of less than 2% to support the maintenance and development of our services. Despite these necessary costs, we strive to make ChainCred as affordable and accessible as possible in the world of secure, blockchain-based ticketing.",
  },
  {
    q: "Is there available support if I have queries or issues with ChainCred?",
    a: "Yes, our dedicated team is available to provide support should you encounter any issues or have questions regarding ChainCred.",
  },
  {
    q: "Whom should I contact for customer support?",
    a: "For any customer support queries, please reach us at chaincred@gmail.com",
  },
];

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ChainCred</title>
      </Helmet>

      <Box bg="gray.50">
        <HeroSection />
        <Container id="features" maxW="container.md" centerContent py={[8, 28]}>
          <SimpleGrid spacingX={10} spacingY={20} minChildWidth="300px">
            {highlights.map(({ title, description, icon }, i: number) => (
              <Box p={4} rounded="md" key={`highlight_${i}`}>
                <Text fontSize="4xl">{icon}</Text>

                <Text fontWeight={500}>{title}</Text>

                <Text color="gray.500" mt={4}>
                  {description}
                </Text>
              </Box>
            ))}
          </SimpleGrid>
        </Container>

        {/* <Container py={28} maxW="container.lg" w="full" id="pricing">
          <Pricing />
        </Container> */}

        <Container id="faqs" py={28} maxW="container.md">
          <Box w="full">
            <VStack spacing={10} w="full">
              <Text fontWeight={500} fontSize="2xl" align="center">
                Frequently asked questions
              </Text>
              <FAQSection items={faqs} />
            </VStack>
          </Box>
        </Container>

        <CTA
          heading={`Get started with ChainCred today!`}
          cta={{ name: "Access Now", link: APP_URL }}
        />

        <Container maxW="container.lg">
          <Flex py={6}>
            <Box>
              <Text>© 2023 ChainCred</Text>

              <Text>Made by ChainCred</Text>
            </Box>
            <Spacer />

            {/* <LinkBox>
              <LinkOverlay href="https://twitter.com/@thisissukh_" isExternal>
                <Image src="twitter.svg" alt="Twitter logo"></Image>
              </LinkOverlay>
            </LinkBox> */}
          </Flex>
        </Container>
      </Box>
    </>
  );
};
export default LandingPage;
