import {
  Button,
  Center,
  Container,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { APP_URL } from "../utils/constants";
import logo from '../logo.png'

interface HeroSectionProps { }

const HeroSection: FunctionComponent<HeroSectionProps> = () => {
  return (
    <Container maxW="container.lg">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Container maxW="container.md" textAlign="center">
            <Heading size="2xl" mb={4} color="gray.700">
            ChainCred: Revolutionizing Ticketing with Non-Transferable NFTs – Own Your Experience
            </Heading>

            <Text fontSize="xl" color="gray.500">
            ChainCred plunges into the heart of event access, reshaping your ticketing experience. Harness the power of unique, non-transferable NFTs that promise bulletproof security and put an end to counterfeit tickets. With ChainCred, every ticket is a testament to the unbreakable strength of the blockchain, embodying an untainted chain of credibility. Dive into this next-level ticketing experience and feel the thrill of true ownership. Your ticket, your experience, indisputably yours with ChainCred.
            </Text>

            <Button mt={8} bg="#0c2840" color="#fff" _hover={{ bg: "#061421" }} onClick={() => window.open(APP_URL, "_blank")}>
              <Image src={logo} alt="Logo" boxSize="25px" mr={2} />
              Access Now
            </Button>
          </Container>
        </VStack>
      </Center>
    </Container>
  );
};
export default HeroSection;
