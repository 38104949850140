import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  chakra,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Image,
  Link,
  Spacer,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import headerLogo from "../headerLogo.png";
import { APP_URL } from "../utils/constants";

const DesktopSidebarContents = ({ name }: any) => {

  const navLinks = [
    { name: "Home", link: "/" },
    { name: "Features", link: "#features" },
    { name: "FAQs", link: "#faqs" },
    { name: "Access Now", link: APP_URL },
  ];

  return (
    <Container maxW={["full", "container.lg"]} p={0}>
      <Stack
        justify="space-between"
        p={[0, 4]}
        w="full"
        direction={["column", "row"]}
      >
        <Box display={{ base: "none", md: "flex" }}>
          <Image src={headerLogo} alt="Logo" boxSize="25px" />
          <Heading fontSize="xl" ml={2}>
            {name}
          </Heading>
        </Box>
        <Spacer />
        <Stack
          align="flex-start"
          spacing={[4, 10]}
          direction={["column", "row"]}
        >
          {navLinks.map((navLink: any, i: number) => {
            return (
              <Link
                href={navLink.name === "Home" ? "/" : `${navLink.link}`}
                key={`navlink_${i}`}
                fontWeight={500}
                variant="ghost"
                isExternal={navLink.name === 'Access Now'}
              >
                {navLink.name}
              </Link>
            );
          })}
        </Stack>
        <Spacer />
      </Stack>
    </Container >
  );
};
const MobileSidebar = ({ name }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex w="full" align="center">
        <Image src={headerLogo} alt="Logo" boxSize="25px" />
        <Heading fontSize="xl" ml={2}>
          {name}
        </Heading>
        <Spacer />
        <IconButton
          aria-label="Search database"
          icon={<HamburgerIcon />}
          onClick={onOpen}
        />
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
          <DrawerOverlay />
          <DrawerContent bg="gray.50">
            <DrawerCloseButton />

            <DrawerHeader>
              <Flex>
                {" "}
                <Image src={headerLogo} alt="Logo" boxSize="25px" />
                <Heading fontSize="xl" ml={2}>
                  {name}
                </Heading>
              </Flex>
            </DrawerHeader>

            <DrawerBody>
              <DesktopSidebarContents />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
};

interface SidebarProps {
  name: string;
}

const Sidebar = ({ name }: SidebarProps) => {
  return (
    <chakra.header id="header">
      <Box display={{ base: "flex", md: "none" }} p={4}>
        <MobileSidebar name={name} />
      </Box>

      <Box display={{ base: "none", md: "flex" }} bg="gray.50">
        <DesktopSidebarContents name={name} />
      </Box>
    </chakra.header>
  );
};

interface HeaderProps {
  name: string;
}

const Header = ({ name }: HeaderProps) => {
  return (
    <Box w="full">
      <Sidebar name={name} />
    </Box>
  );
};

export default Header;
