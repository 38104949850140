import { Box, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import Header from "../components/Header";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <Box bg="gray.50">
      <VStack spacing={10} w="full" align="center">
        <Header name="ChainCred" />
        {children}
      </VStack>
    </Box>
  );
};
export default Layout;
